<template>
  <div class="row">
    <b-container fluid>
      <b-card no-body class="">
        <b-overlay :show="isLoading" rounded="sm">
          <div class="m-1">
            <b-row>
              <b-col cols="12"><h3>{{modalTitle}}</h3></b-col>
            </b-row>
            <b-row class="my-0">
              <b-col cols="12">
                <vue-good-table
                  mode="remote"
                  class="my-table"
                  row-style-class="vgt-row"
                  styleClass="vgt-table striped"
                  :columns="columns"
                  :rows="mainData"
                  :pagination-options="paginationOptions"
                  :total-rows="totalRows"
                  :fixed-header="true"
                  max-height="550px"
                  @on-page-change="onPageChange"
                  @on-sort-change="onSortChange"
                  @on-column-filter="onColumnFilter"
                  @on-per-page-change="onPerPageChange"
                >
                  <div
                    slot="emptystate"
                    style="text-align: center; font-weight: bold"
                  >
                    Không có bản ghi nào !
                  </div>
                  <template
                    slot="table-row"
                    slot-scope="props"
                  >
                    <span v-if="props.column.field === 'status'"
                          :class="props.row.status?'text-success':'text-danger'"
                          v-html="getStatusName(props.row.status)"
                    ></span>
                    <span v-else-if="props.column.field === 'replaceThesis'"
                          :class="props.row.replaceThesis?'text-danger':'text-success'"
                          v-html="getThesisSubjectTypeName(props.row.replaceThesis)"
                    ></span>

                    <span v-else-if="props.column.field === 'countedForGpa'"
                          :class="props.row.countedForGpa?'text-success':'text-danger'"
                          v-html="getCountedForGpaName(props.row.countedForGpa)"
                    ></span>
                    <span v-else-if="props.column.field === 'isOptional'"
                          :class="props.row.isOptional?'text-danger':'text-success font-weight-bolder'"
                          v-html="getIsOptionalTypesName(props.row.isOptional)"
                    ></span>

                    <span v-else-if="props.column.field === 'totalCreditNum'">
                      {{ showCreditSummary(props.row) }}
                    </span>
                    <!-- Column: Common -->
                    <span v-else-if="props.column.field === 'alterCode'" v-b-tooltip.hover.top="props.row.alterName">
                      {{ props.row.alterCode }}
                    </span>
                    <span v-else-if="props.column.field === 'conditionCode'" v-b-tooltip.hover.top="props.row.conditionName">
                      {{ props.row.conditionCode }}
                    </span>
                    <span v-else-if="props.column.field === 'groupCode'" v-b-tooltip.hover.top="props.row.groupName">
                      {{ props.row.groupCode }}
                    </span>
                    <span v-else>{{ props.formattedRow[props.column.field] }}</span>
                  </template>

                  <!-- pagination -->
                  <template
                    slot="pagination-bottom"
                    slot-scope="props"
                  >
                    <div class="d-flex justify-content-between flex-wrap">
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị {{filter.itemsPerPage * (filter.currentPage-1) +1}}
                          đến {{filter.itemsPerPage * (filter.currentPage)}} &nbsp; của {{ totalRows }} bản ghi
                        </span>
                      </div>
                      <div class="d-flex align-items-center mb-0 mt-1">
                        <span class="text-nowrap"> Hiển thị </span>
                        <b-form-select
                          v-model="filter.itemsPerPage"
                          :options="itemsPerPageOptions"
                          class="mx-1"
                          @input="(value) => props.perPageChanged({ currentPerPage: value })"
                        />
                        <span class="text-nowrap"> bản ghi/trang</span>
                      </div>
                      <div>
                        <b-pagination
                          :value="1"
                          :total-rows="totalRows"
                          :per-page="filter.itemsPerPage"
                          class="mt-1 mb-0"
                          @input="(value) => props.pageChanged({ currentPage: value })"
                        />
                      </div>
                    </div>
                  </template>
                </vue-good-table>
              </b-col>
            </b-row>
          </div>
        </b-overlay>
      </b-card>
    </b-container>
  </div>
</template>

<script>
import {
  BCard,
  BCol,
  BContainer,
  BFormSelect,
  BOverlay,
  BPagination,
  BRow,
  VBTooltip,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
import { mapActions, mapGetters } from 'vuex'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import '@core/scss/vue/libs/vue-good-table.scss'
import Ripple from 'vue-ripple-directive'

export default {
  name: 'ProgrammeSubject',
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  components: {
    BCard,
    BCol,
    BContainer,
    BFormSelect,
    BOverlay,
    BPagination,
    BRow,
    VueGoodTable,
  },
  props: {
    dataSend: {
      type: Object,
      // eslint-disable-next-line vue/require-valid-default-prop
      default: {},
    },
  },
  data() {
    return {
      isLoading: false,
      filter: {
        currentPage: 1,
        itemsPerPage: 10,
        programmeId: this.dataSend.id || this.$route.params.id,
        courseSemesterId: '',
        groupId: '',
        code: '',
        name: '',
        status: '',
        sort: '',
      },
      modalTitle: `Danh sách học phần của CTĐT - [${this.dataSend.code}] ${this.dataSend.name}`,
      currentMainData: undefined,
      extMainData: {
        parentId: this.dataSend.id || this.$route.params.id,
        courseId: this.dataSend.courseId,
      },
      paginationOptions: {
        enabled: true,
      },
      itemsPerPageOptions: [10, 20, 30, 50, 80, 100],
    }
  },
  computed: {
    ...mapGetters({
      mainData: 'programmeSubject/dataLists',
      totalRows: 'programmeSubject/totalRows',
      statusSymbols: 'programmeSubject/statusSymbols',
      countedForGpaTypes: 'programmeSubject/countedForGpaTypes',
      isOptionalTypes: 'programmeSubject/isOptionalTypes',
      resourceName: 'programmeSubject/resourceName',
      replaceThesisSubjectTypes: 'programmeSubject/replaceThesisSubjectTypes',
      subjectGroups: 'programmeSubjectGroup/dataLists',
      courseSemester: 'dropdown/courseSemesters',
    }),
    replaceThesisFilterDropdownItems() {
      return this.replaceThesisSubjectTypes.map(item => ({ value: item.value, text: item.label }))
    },
    groupFilterDropdownItems() {
      return this.subjectGroups.map(item => ({ value: item.id, text: item.name }))
    },
    semesterFilterDropdownItems() {
      return this.courseSemester.map(item => ({ value: item.value, text: item.label }))
    },
    columns() {
      return [
        {
          label: '#',
          field: 'rowNum',
          sortable: false,
          thClass: 'text-center',
          tdClass: 'text-right',
        },
        {
          label: `Mã ${this.resourceName.shortName}`,
          field: 'code',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: `Tên ${this.resourceName.shortName}`,
          field: 'name',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tổng TC (LT_TH_TT)',
          field: 'totalCreditNum',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'HP thay thế',
          field: 'alterCode',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'HP điều kiện',
          field: 'conditionCode',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Học kỳ',
          field: 'semesterName',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.semesterFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          width: '120px',
          sortable: true,
          thClass: 'text-center',
        },
        {
          label: 'Tính điểm',
          field: 'countedForGpa',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Bắt buộc',
          field: 'isOptional',
          filterOptions: {
            enabled: false,
            trigger: 'enter',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
        {
          label: 'Nhóm HP',
          field: 'groupCode',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.groupFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-left',
        },
        {
          label: 'TT KLTN',
          field: 'replaceThesis',
          filterOptions: {
            enabled: true,
            trigger: 'enter',
            filterDropdownItems: this.replaceThesisFilterDropdownItems,
            placeholder: 'Tất cả',
          },
          sortable: true,
          thClass: 'text-center',
          tdClass: 'text-center',
        },
      ]
    },
  },
  async created() {
    this.isLoading = true
    try {
      await Promise.all([
        this.getMainData(this.filter),
        this.getCourseSemester({ courseId: this.dataSend.courseId, status: '', typeId: 1 }),
        this.getSubjectGroup({
          programmeId: this.filter.programmeId,
          currentPage: 1,
          itemsPerPage: 100,
          status: 1,
        }),
      ])
    } catch (e) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: `[${e.code}] ${e.message}`,
          icon: 'XCircleIcon',
          variant: 'danger',
        },
      })
    } finally {
      this.isLoading = false
    }
  },
  methods: {
    ...mapActions({
      getMainData: 'programmeSubject/getData',
      getCourseSemester: 'dropdown/getCourseSemesters',
      getSubjectGroup: 'programmeSubjectGroup/getData',
    }),
    updateParams(newProps) {
      this.filter = { ...this.filter, ...newProps }
    },
    async onPageChange(param) {
      this.updateParams({ currentPage: param.currentPage })
      await this.getMainDataFromStore()
    },
    async getMainDataFromStore() {
      this.isLoading = true
      try {
        await this.getMainData(this.filter)
      } catch (e) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: `[${e.code}] ${e.message}`,
            icon: 'XCircleIcon',
            variant: 'danger',
          },
        })
      } finally {
        this.isLoading = false
      }
    },
    async onPerPageChange(param) {
      this.updateParams({ currentPage: param.currentPage, itemsPerPage: param.currentPerPage })
      await this.getMainDataFromStore()
    },
    async onSortChange(param) {
      if (param.length > 0) {
        const { field, type } = param[0]
        const selectedColumn = this.columns.findIndex(e => e.field === field)

        this.updateParams({ sort: `${selectedColumn}_${type}` })
      }
      await this.getMainDataFromStore()
    },
    async onColumnFilter(param) {
      const { columnFilters } = param
      if (Object.hasOwn(columnFilters, 'name')) this.updateParams({ name: columnFilters.name })
      if (Object.hasOwn(columnFilters, 'code')) this.updateParams({ code: columnFilters.code })
      if (Object.hasOwn(columnFilters, 'status')) this.updateParams({ status: columnFilters.status })
      if (Object.hasOwn(columnFilters, 'groupCode')) this.updateParams({ groupId: columnFilters.groupCode })
      if (Object.hasOwn(columnFilters, 'semesterName')) this.updateParams({ courseSemesterId: columnFilters.semesterName })
      if (Object.hasOwn(columnFilters, 'replaceThesis')) this.updateParams({ replaceThesis: columnFilters.replaceThesis })
      await this.getMainDataFromStore()
    },

    getThesisSubjectTypeName(id) {
      return this.replaceThesisSubjectTypes.find(status => status.value === id).label
    },
    getCountedForGpaName(id) {
      return this.countedForGpaTypes.find(item => item.value === id).label
    },
    getIsOptionalTypesName(id) {
      return this.isOptionalTypes.find(item => item.value === id).label
    },
    showCreditSummary(row) {
      const summary = `${row.totalCreditNum} (${
        row.theoryCreditNum}_${
        row.practiceCreditNum}_${
        row.internshipCreditNum})`
      return summary
    },
  },
  mounted() {
    const grid = document.querySelector('.vgt-responsive')
    grid.classList.add('overlay')
    grid.classList.add('custom')
  },
}
</script>
<style lang="css">
/* Scrollbar */
.my-table.vgt-wrap .vgt-responsive,
.overlay {
  overflow-y: overlay;
}
.my-table.vgt-wrap .vgt-responsive::-webkit-scrollbar,
.overlay.custom:hover::-webkit-scrollbar {
  display: initial;
}
.my-table.vgt-wrap .vgt-responsive::-webkit-scrollbar,
.overlay.custom::-webkit-scrollbar {
  display: initial;
}

/* custom webkit scrollbars */
.my-table.vgt-wrap .vgt-responsive::-webkit-scrollbar,
.custom::-webkit-scrollbar {
  width: 10px;
}
.my-table.vgt-wrap .vgt-responsive::-webkit-scrollbar-track,
.custom::-webkit-scrollbar-track {
  background-color: white;
  border-width: 0;
}
.my-table.vgt-wrap .vgt-responsive::-webkit-scrollbar-thumb,
.custom::-webkit-scrollbar-thumb {
  border-radius: 20px;
  background-color: rgba(0, 0, 0, .25);
  border-style: solid;
  border-color: transparent;
  border-width: 3px;
  background-clip: padding-box;
}

/* hidden elements */
.my-table.vgt-wrap .vgt-responsive::-webkit-scrollbar-button,
.my-table.vgt-wrap .vgt-responsive::-webkit-scrollbar-track-piece,
.my-table.vgt-wrap .vgt-responsive::-webkit-scrollbar-corner,
.my-table.vgt-wrap .vgt-responsive::-webkit-resizer,
.custom::-webkit-scrollbar-button,
.custom::-webkit-scrollbar-track-piece,
.custom::-webkit-scrollbar-corner,
.custom::-webkit-resizer {
  display: none;
}
tr.vgt-row{
  transition: background-color .1s;
}
tr.vgt-row:hover {
  background: #eef8ff;
}
.vgt-table thead{
  background-color: #f8f8f8;
}
</style>
